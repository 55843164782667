import * as React from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Modal from "@mui/material/Modal";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardPng from "assets/png/dashboard.png";
import BuildingPng from "assets/png/building.png";
import FacilitiesPng from "assets/png/facilities.png";
import ScenarioPng from "assets/png/scenario.png";
import StandardPng from "assets/png/standard.png";
import ZastiLogo from "assets/png/zastilogo.png";
import MenuLogo from "assets/png/Menu.png";
import ProfileLogo from "assets/png/profile.png";
import User from "assets/png/user.png";
import Exit from "assets/png/exit.png";
import KeyIcon from "assets/png/key.png";
import IndHempLogo from "assets/png/indHemp.png";
import AtomLogo from "assets/png/atom.svg";
import { Avatar } from "@mui/material";
import { PrivateRoutes as PrivateRoutesDirectives } from "routes/routeConstans";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "store/actions/UserAction";
import { useAppSelector } from "store/hooks";
import { MASTER_ENDPOINT } from "utils/Constant";
import LaunchIcon from "@mui/icons-material/Launch";
import moment from "moment";
import CustomerJpg from "assets/png/customers.jpg";

const drawerWidth = 240;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: " 8px",
  background: " #FFF",
  boxShadow: "0px 2px 4px 0px rgba(52, 64, 81, 0.25)",
  padding: "24px",
};

const openedMixin = (theme: Theme): CSSObject => ({
  backgroundColor: "#0B0B0B",
  color: "#fff",
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  backgroundColor: "#0B0B0B",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const loginimgopen = (): CSSObject => ({
  display: "flex",
  paddingRight: 15,
  paddingLeft: 15,
});

const loginimgclose = (): CSSObject => ({
  display: "none",
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
    "& .sidebaravatar": loginimgopen(),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
    "& .sidebaravatar": loginimgclose(),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(0, 1),
  height: 75,
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  height: 75,
  justifyContent: "center",
  background: "#FFF",
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

type LayoutConfigsProps = {
  path: string;
  children: any;
};

const drawerStyle = {
  text: {
    fontFamily: "Poppins-Regular !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
    fontWeight: "500",
    marginLeft: "16px",
  },
  icon: {
    height: 20,
    width: 20,
  },
  iconMenu: {
    height: 22,
    width: 22,
  },
  zastiIcon: {
    height: 26,
    width: 75,
  },
  listButton: {
    borderRadius: "4px",
    margin: "15px",
  },
  versionText: {
    // position: 'absolute',
    // right: 15,
    fontFamily: "Poppins-Regular !important",
    fontSize: "12px !important",
    lineHeight: "20px !important",
    fontWeight: "400",
  },
  organizationLogo: {
    width: "100%",
    height: 55,
    objectFit: "contain",
  },
  nameLogo: {
    height: 32,
    width: 32,
    borderRadius: 16,
    backgroundColor: "#28A9E1",
    color: "#fff !important",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Poppins-SemiBold !important",
    fontSize: "14px !important",
    lineHeight: "24px !important",
    fontWeight: "500",
  },
  nameLogo2: {
    height: 40,
    width: 40,
    borderRadius: 16,
    backgroundColor: "#28A9E1",
    color: "#fff !important",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Poppins-SemiBold !important",
    fontSize: "14px !important",
    lineHeight: "24px !important",
    fontWeight: "500",
  },
  nametext: {
    fontFamily: "Poppins-SemiBold !important",
    fontSize: "14px !important",
    lineHeight: "20px !important",
    fontWeight: "500",
    color: "#111",
    textTransform: "capitalize",
    marginRight: "10px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: "10vw",
  },
  nametext2: {
    fontFamily: "Poppins-SemiBold !important",
    fontSize: "16px !important",
    lineHeight: "20px !important",
    fontWeight: "500",
    color: "#111",
    textTransform: "capitalize",
    marginRight: "10px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: "100px",
  },
  profilePng: {
    height: 20,
    width: 24,
  },
  role: {
    color: "#637083",
    fontSize: "12px",
    lineHeight: "14px",
  },
};

const PrivateLayout = (props: LayoutConfigsProps) => {
  const showButtons = false;

  const {
    DASHBOARD,
    ORGANIZATION,
    FACILITIES,
    SCENARIO,
    STANDARD,
    CHANGEPASSWORD,
    MYPROFILE,
    USERS,
    CUSTOMERS,
  } = PrivateRoutesDirectives;
  const dispatch: any = useDispatch();
  const userDetail: any = useAppSelector((store: any) => {
    return store.userLogin.userInfo;
  });
  const { organizationLogo } = useAppSelector((store: any) => {
    return store.userLogin;
  });
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [openmobile, setOpenmobile] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorEllook, setAnchorEllook] =
    React.useState<HTMLButtonElement | null>(null);

  const handlelookClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEllook(event.currentTarget);
  };

  const handlelookClose = () => {
    setAnchorEllook(null);
  };

  const openprofile = Boolean(anchorEl);
  const id = openprofile ? "simple-popover" : undefined;

  const openlook = Boolean(anchorEllook);
  const lookid = openlook ? "simple-popover" : undefined;

  const handleDrawerOpen = () => {
    setOpen(true);
    setOpenmobile(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setOpenmobile(false);
  };

  const logoutFunction = () => {
    logout(dispatch);
  };

  const [opensignout, setOpensignout] = React.useState(false);
  const handleSignoutOpen = () => setOpensignout(true);
  const handleSignoutClose = () => setOpensignout(false);

  return (
    <Box
      className="mobileContainer"
      sx={{ display: "flex", minHeight: "100vh" }}
    >
      <CssBaseline />
      <AppBar position="fixed" open={open} className="mobileHeader">
        <Toolbar>
          {!open && (
            <Typography component={"div"} className={"webclose"}>
              <Avatar
                className="weblogo"
                variant="square"
                alt="Zasti"
                sx={{ objectFit: "contain" }}
                src={
                  organizationLogo
                    ? `${process.env.REACT_APP_APIURL}${MASTER_ENDPOINT.files}?id=${organizationLogo}`
                    : IndHempLogo
                }
              />
              <IconButton
                className={
                  open ? "sideBarActive mnone " : "sideBarActive mnone"
                }
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  display: open ? "none" : "inherit",
                }}
              >
                <Avatar
                  sx={drawerStyle.iconMenu}
                  variant="square"
                  alt="Zasti"
                  src={MenuLogo}
                />
              </IconButton>
            </Typography>
          )}
          <IconButton
            className="sideBarActive mbarclose"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              display: open ? "none" : "inherit",
            }}
          >
            <Avatar
              sx={drawerStyle.iconMenu}
              variant="square"
              alt="Zasti"
              src={MenuLogo}
            />
          </IconButton>

          <Typography component={"div"}>
            <Avatar
              // className='weblogo'
              variant="square"
              alt="Zasti"
              className="mobile-atom"
              sx={{
                "& img": { objectFit: "none" },
                marginLeft: "10px",
                width: "150px",
                height: "50px",
              }}
              src={AtomLogo}
            />
          </Typography>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
          />
          {process.env.REACT_APP_ENV !== "PRODUCTION" && showButtons && (
            <>
              <Button
                className="save-btn w280-block"
                aria-describedby={lookid}
                onClick={handlelookClick}
              >
                Lookup
              </Button>
              <Popover
                className="look-popup"
                id={lookid}
                open={openlook}
                anchorEl={anchorEllook}
                onClose={handlelookClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Typography component={"div"}>
                  <Typography
                    component={"a"}
                    target={"_blank"}
                    href={"http://54.71.89.6:8031"}
                    className="save-btn mb-4"
                    sx={{
                      marginRight: "20px",
                      textDecoration: "none",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    PFA{" "}
                    <LaunchIcon fontSize="small" sx={{ marginLeft: "5px" }} />
                  </Typography>
                  <Typography
                    component={"a"}
                    target={"_blank"}
                    href={"http://54.71.89.6:8035/"}
                    className="save-btn"
                    sx={{
                      marginRight: "20px",
                      textDecoration: "none",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    EPD Lookup
                    <LaunchIcon fontSize="small" sx={{ marginLeft: "5px" }} />
                  </Typography>
                </Typography>
              </Popover>

              <Typography
                component={"a"}
                target={"_blank"}
                href={"http://54.71.89.6:8031"}
                className="save-btn  w280"
                sx={{
                  marginRight: "20px",
                  textDecoration: "none",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                PFA <LaunchIcon fontSize="small" sx={{ marginLeft: "5px" }} />
              </Typography>
              <Typography
                component={"a"}
                target={"_blank"}
                href={"http://54.71.89.6:8035/"}
                className="save-btn w280"
                sx={{
                  marginRight: "20px",
                  textDecoration: "none",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                EPD Lookup
                <LaunchIcon fontSize="small" sx={{ marginLeft: "5px" }} />
              </Typography>
            </>
          )}
          {(userDetail.roleId === 1 || userDetail.roleId === 2) && (
            <Link to={USERS.LIST} className="linkHide">
              <IconButton
                type={"button"}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: 24,
                }}
              >
                <Avatar
                  sx={drawerStyle.profilePng}
                  variant="square"
                  alt="Zasti"
                  src={ProfileLogo}
                />
              </IconButton>
            </Link>
          )}
          <Button aria-describedby={id} onClick={handleClick}>
            <Typography
              component={"div"}
              sx={{ alignItems: "center", display: "inline-flex" }}
            >
              <Typography component={"div"} sx={drawerStyle.nametext}>
                {userDetail?.firstName}
              </Typography>
              <Typography component={"div"} sx={drawerStyle.nameLogo}>
                {userDetail?.firstName &&
                  userDetail?.firstName.charAt(0).toUpperCase("")}
                {userDetail?.lastName &&
                  userDetail.lastName.charAt(0).toUpperCase("")}
              </Typography>
            </Typography>
          </Button>
          <Popover
            className="profile-popup"
            id={id}
            open={openprofile}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Typography component={"div"}>
              <Typography
                component={"div"}
                className="pop-head in-flex"
                sx={{ width: "100%" }}
              >
                <Typography component={"div"} sx={drawerStyle.nameLogo2}>
                  {userDetail?.firstName &&
                    userDetail?.firstName.charAt(0).toUpperCase("")}
                  {userDetail?.lastName &&
                    userDetail?.lastName.charAt(0).toUpperCase("")}
                </Typography>
                <Typography sx={{ paddingLeft: "10px" }}>
                  <Typography component={"div"} sx={drawerStyle.nametext2}>
                    {userDetail?.firstName && userDetail?.firstName}
                  </Typography>
                  <Typography sx={drawerStyle.role}>
                    {userDetail?.roleData?.name}
                  </Typography>
                </Typography>
              </Typography>
              <Typography
                component={"div"}
                className="pop-item-box"
                sx={{ display: "flex", flexDirection: "column", width: "100%" }}
              >
                <Link to={MYPROFILE} className={"linkHide"}>
                  <Typography component={"div"} className="in-flex pop-item">
                    <img src={User} alt="my profile" />
                    <Typography className="pop-text">My Profile</Typography>
                  </Typography>
                </Link>
                <Link to={CHANGEPASSWORD} className={"linkHide"}>
                  <Typography component={"div"} className="in-flex pop-item">
                    <img src={KeyIcon} alt="key" />
                    <Typography className="pop-text">
                      Change Password
                    </Typography>
                  </Typography>
                </Link>
                <Typography
                  component={"div"}
                  className="in-flex pop-item pop-item-red"
                  onClick={handleSignoutOpen}
                >
                  <img src={Exit} alt="exit" />
                  <Typography className="pop-text">Sign Out</Typography>
                </Typography>
                <Modal
                  open={opensignout}
                  onClose={handleSignoutClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style} className="mobileOut">
                    <Typography className="modal-title">Sign Out?</Typography>
                    <Typography className="modal-dis">
                      Are you sure you want to Signout?
                    </Typography>
                    <Typography component={"div"} className="in-flex flex-end">
                      <Button
                        className="cancel-btn btn-2r"
                        onClick={handleSignoutClose}
                      >
                        Cancel
                      </Button>
                      <Button className="save-btn" onClick={logoutFunction}>
                        Sign Out
                      </Button>
                    </Typography>
                  </Box>
                </Modal>
              </Typography>
            </Typography>
          </Popover>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        className={openmobile ? "mobileClose" : "mobileOpen"}
      >
        <DrawerHeader>
          <Avatar
            sx={drawerStyle.organizationLogo}
            className="object-fit-contain"
            // variant='square'
            alt="Zasti"
            src={
              organizationLogo
                ? `${process.env.REACT_APP_APIURL}${MASTER_ENDPOINT.files}?id=${organizationLogo}`
                : IndHempLogo
            }
          />
          <IconButton
            onClick={handleDrawerClose}
            sx={{ position: "absolute", right: 5 }}
          >
            {theme.direction === "ltr" ? (
              <Avatar
                sx={drawerStyle.iconMenu}
                variant="square"
                alt="Zasti"
                src={MenuLogo}
              />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider sx={{ background: "#f5f5f5" }} />
        <List>
          <ListItem disablePadding sx={{ display: "block" }}>
            <Link to={DASHBOARD} className={"sidebarLink"}>
              <ListItemButton
                className={props.path === DASHBOARD ? "sideBarActive" : ""}
                sx={{
                  ...drawerStyle.listButton,
                  justifyContent: open ? "initial" : "center",
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Avatar
                    sx={drawerStyle.icon}
                    variant="square"
                    alt="Dashboard"
                    src={DashboardPng}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={"Dashboard"}
                  sx={{
                    opacity: open ? 1 : 0,
                    "& .MuiTypography-root": drawerStyle.text,
                  }}
                />
              </ListItemButton>
            </Link>
          </ListItem>
          {userDetail.roleId === 1 && (
            <ListItem disablePadding sx={{ display: "block" }}>
              <Link to={ORGANIZATION} className={"sidebarLink"}>
                <ListItemButton
                  className={props.path === ORGANIZATION ? "sideBarActive" : ""}
                  sx={{
                    ...drawerStyle.listButton,
                    justifyContent: open ? "initial" : "center",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <Avatar
                      sx={drawerStyle.icon}
                      variant="square"
                      alt="My Organization"
                      src={BuildingPng}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={"My Organization"}
                    sx={{
                      opacity: open ? 1 : 0,
                      "& .MuiTypography-root": drawerStyle.text,
                    }}
                  />
                </ListItemButton>
              </Link>
            </ListItem>
          )}
          {userDetail.roleId !== 4 && (
            <ListItem disablePadding sx={{ display: "block" }}>
              <Link to={FACILITIES.LIST} className={"sidebarLink"}>
                <ListItemButton
                  className={
                    props.path === FACILITIES.LIST ||
                    props.path === FACILITIES.ADD
                      ? "sideBarActive"
                      : ""
                  }
                  sx={{
                    ...drawerStyle.listButton,
                    justifyContent: open ? "initial" : "center",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <Avatar
                      sx={drawerStyle.icon}
                      variant="square"
                      alt="Facilities"
                      src={FacilitiesPng}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Facilities"}
                    sx={{
                      opacity: open ? 1 : 0,
                      "& .MuiTypography-root": drawerStyle.text,
                    }}
                  />
                </ListItemButton>
              </Link>
            </ListItem>
          )}
          {userDetail.roleId === 1 && (
            <ListItem disablePadding sx={{ display: "block" }}>
              <Link to={STANDARD} className={"sidebarLink"}>
                <ListItemButton
                  className={props.path === STANDARD ? "sideBarActive" : ""}
                  sx={{
                    ...drawerStyle.listButton,
                    justifyContent: open ? "initial" : "center",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <Avatar
                      sx={drawerStyle.icon}
                      variant="square"
                      alt="Standards"
                      src={StandardPng}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Parameters"}
                    sx={{
                      opacity: open ? 1 : 0,
                      "& .MuiTypography-root": drawerStyle.text,
                    }}
                  />
                </ListItemButton>
              </Link>
            </ListItem>
          )}
          <ListItem disablePadding sx={{ display: "block" }}>
            <Link to={SCENARIO.LIST} className={"sidebarLink"}>
              <ListItemButton
                className={
                  props.path === SCENARIO.LIST || props.path === SCENARIO.TYPE
                    ? "sideBarActive"
                    : ""
                }
                sx={{
                  ...drawerStyle.listButton,
                  justifyContent: open ? "initial" : "center",
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Avatar
                    sx={drawerStyle.icon}
                    variant="square"
                    alt="Scenarios"
                    src={ScenarioPng}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={"Scenarios"}
                  sx={{
                    opacity: open ? 1 : 0,
                    "& .MuiTypography-root": drawerStyle.text,
                  }}
                />
              </ListItemButton>
            </Link>
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }}>
            <Link to={CUSTOMERS.LIST} className={"sidebarLink"}>
              <ListItemButton
                className={props.path === CUSTOMERS.LIST ? "sideBarActive" : ""}
                sx={{
                  ...drawerStyle.listButton,
                  justifyContent: open ? "initial" : "center",
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Avatar
                    sx={drawerStyle.icon}
                    variant="square"
                    alt="Customers"
                    src={CustomerJpg}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={"Customers"}
                  sx={{
                    opacity: open ? 1 : 0,
                    "& .MuiTypography-root": drawerStyle.text,
                  }}
                />
              </ListItemButton>
            </Link>
          </ListItem>
        </List>
        <Box className="sidebar-footer">
          <Typography
            component={"div"}
            className="sidebaravatar"
            justifyContent={"center"}
          >
            <Avatar
              sx={drawerStyle.zastiIcon}
              variant="square"
              alt="Zasti"
              src={ZastiLogo}
            />
          </Typography>
          <Typography
            component="div"
            sx={drawerStyle.versionText}
            textAlign={"center"}
          >
            V1.0
          </Typography>
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{ backgroundColor: "#f5f5f5", flexGrow: 1 }}
        className={open ? "w240" : "w64"}
      >
        <DrawerHeader />
        <Box sx={{ p: 3 }} className="mh-85">
          {props.children}
        </Box>
        <Typography component={"div"} className="d-flex-a flex-sb footer-box">
          <Typography className="footer-text">
            © Copyright {moment().format("YYYY")} | All rights reserved.
          </Typography>
          <Typography component={"div"} className="d-flex-a">
            <Typography className="footer-text">Privacy Policy</Typography>
            <Typography className="fbar" />
            <Typography className="footer-text">Terms of service </Typography>
          </Typography>
        </Typography>
      </Box>
    </Box>
  );
};

export default PrivateLayout;
